<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Downloads': 'Downloads',
    },
    es: {
      'Downloads': 'Descargas',
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      folders: null,
    };
  },
  methods: {
    getList() {
      api
        .get('downloads')
        .then((response) => {
          if (response.data.status == 'success') {
            this.folders = response.data.folders
          }
        })
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Suporte</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>{{ t('Downloads') }}</h3>
        <p></p>
      </div>
    </div>

    <div v-if="!folders" class="text-center">
      <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
    </div>
    <div v-else>
      <div v-for="(downloads, index) in folders" :key="index">
        <div v-if="downloads.list.length > 0" class="card">
          <div class="card-header rounded bg-default m-1" v-b-toggle="'files_' + index">
            <h5 class="mb-0 text-white">{{ downloads.name }}</h5>
          </div>
          <b-collapse :id="'files_' + index" class="card-body">
            <div class="card-files row">
              <div class="card-file col-md-3 text-center" v-for="(row, index) in downloads.list" :key="index">
                <img class="file-cover" :src="row.file.cover" />
                <h5 class="mb-3 font-size-18">{{ row.title }}</h5>
                <a target="_blank" class="btn btn-outline-default btn-block" :href="row.file.url">Download</a>
                <div class="d-flex justify-content-center mt-2">
                  <div class="flex-fill font-size-12 text-uppercase px-1">
                    {{ row.file.type }}<br>
                    <span class="bg-soft-default rounded font-size-11 px-1">FORMATO</span>
                  </div>
                  <div class="flex-fill font-size-12 px-1">
                    {{ (row.file.size / 1024).toFixed(2) }} MB<br>
                    <span class="bg-soft-default rounded font-size-11 px-1">TAMANHO</span>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.file-cover {
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 10 / 5;
}

@media (max-width: 500px) {
  .card-file {
    margin-bottom: 25px;
  }
}
</style>
