<script>
export default {
  components: {},
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
  props: {
    cart: {
      default: "",
    },
  },
};
</script>

<template>
  <div id="footer-store">
  </div>
</template>