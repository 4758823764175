<script>
import Layout from "@/router/layouts/store";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Qty from "@/components/widgets/store-qty";
import Shipping from "@/components/widgets/store-shipping";

export default {
  locales: {
    pt: {
      'Cart': 'Carrinho',
    },
    es: {
      'Cart': 'Cesta',
    }
  },
  components: {
    Layout,
    VclList,
    Qty,
    Shipping,
  },
  data() {
    return {
      cart: {
        partner: null,
        items: {
          loading: true,
          total: 0,
          list: null
        },
        points: {
          unilevel: 0,
          graduation: 0,
        },
        subtotal: '0.00',
        shipping: {
          method: '',
          value: '0.00',
        },
        total: '0.00'
      },
      checkout: {
        loading: false,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    getCart() {
      api
        .get('store/carts/' + this.$route.params.id)
        .then(response => {
          if (response.data.status == 'success') {
            this.cart.items.loading = false

            this.cart.partner = response.data.partner
            this.cart.items.total = response.data.items.total
            this.cart.items.list = response.data.items.list
            this.cart.points = response.data.points
            this.cart.subtotal = response.data.subtotal
            this.cart.shipping = response.data.shipping
            this.cart.total = response.data.total
          }
        })
        .catch(error => {
          this.cart.errored = error
        })
        .finally(() => {
          this.cart.items.loading = false
        })
    },
    checkoutCart() {
      this.checkout.loading = true

      api
        .post('store/checkout', {
          partner: this.$route.params.id
        })
        .then(response => {
          if (response.data.status == 'success') {
            this.getCart()

            this.$router.push('/store/order/' + response.data.order.id);
          } else if (response.data.status == 'inventory') {
            this.getCart()

            this.$toast.info(response.data.message)
          } else {
            this.$toast.error(response.data.message)
          }
        })
        .catch(error => {
          this.checkout.errored = error
          this.checkout.loading = false
          this.$toast.error('Não foi possível finalizar sua compra, tente novamente.')
        })
        .finally(() => {
          this.checkout.loading = false
        })
    },
  },
  computed: {
    getSubtotal() {
      return this.cart.subtotal
    },
    getShipping() {
      return this.cart.shipping.value
    },
    getTotal() {
      return parseFloat(this.getSubtotal) + parseFloat(this.getShipping)
    },
  },
  mounted() {
    this.getCart()
  }
};
</script>

<template>
  <Layout ref="store">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/store">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/store">Loja Virtual</router-link></li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Carrinho</h3>
        <p></p>
      </div>
    </div>

    <div v-if="cart.partner && cart.partner.length == 0">
      <div class="card">
        <div class="card-body text-center">
          Nenhum informativo encontrado.
        </div>
      </div>
    </div>

    <div v-if="cart.partner" class="card">
      <div class="card-body card-partner">
        <div class="d-flex align-items-center">
          <div class="logo">
            <img v-if="cart.partner.logo" :src="cart.partner.logo">
            <img v-else src="@/assets/images/logo-app.png" />
          </div>
          <div>
            <h5 class="m-0">{{ cart.partner.name }}</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div :class="[cart.items.total > 0 ? 'col-md-7 col-lg-8' : 'col-md-12']">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <vcl-list v-if="cart.items.loading" class="col-lg-6"></vcl-list>
              <div v-else-if="cart.items.total == 0" class="text-center py-5">
                <i class="far fa-sad-cry font-size-24"></i><br>
                seu carrinho está vazio
              </div>
              <table v-else class="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th class="border-0" colspan="2">Produto</th>
                    <th class="border-0 text-center">Qtd</th>
                    <th class="border-0 text-center">Preço</th>
                    <th class="border-0 text-center">Total</th>
                  </tr>
                </thead>
                <tbody v-if="cart.items.total > 0">
                  <tr v-for="(item, id) in cart.items.list" :key="id">
                    <td>
                      <img v-if="item.image" style="width:80px; height:80px; object-fit: cover;" class="" :src="item.image" />
                    </td>
                    <td class="font-weight-medium">
                      {{ item.name }}
                      <span v-if="item.inventory == 0" class="font-weight-normal font-size-12 text-danger text-uppercase">
                        <br>este produto não está mais disponível
                      </span>
                      <!-- <span v-else-if="item.quantity > item.inventory" class="font-weight-normal font-size-12 text-danger text-uppercase">
                        <br>restam apenas {{ item.inventory }} produto
                      </span> -->
                    </td>
                    <td class="text-center">
                      <Qty :id="id" :quantity="item.quantity"></Qty>
                    </td>
                    <td class="text-center">{{ item.price | currency }}</td>
                    <td class="text-center">{{ item.subtotal | currency }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-if="cart.items.total > 0" class="col-md-5 col-lg-4">
        <div v-if="cart.shipping.type == 'receive'" class="card">
          <div class="card-body">
            <h5 class="mb-3">Prazo de Entrega</h5>
            <Shipping></Shipping>
          </div>
        </div>
        <div v-else-if="cart.shipping.type == 'withdraw'" class="card">
          <div class="card-body">
            <h5 class="mb-3">Endereço para Retirada</h5>
            <div v-if="cart.shipping.address.address" class="bg-soft-warning rounded p-3">
              {{ cart.shipping.address.address }},
              {{ cart.shipping.address.number }},
              <template v-if="cart.shipping.address.complement">{{ cart.shipping.address.complement }},</template>
              {{ cart.shipping.address.city }}/{{ cart.shipping.address.state }},
              {{ cart.shipping.address.zipcode }}
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="mb-3">Resumo do Pedido</h5>
            <div class="d-flex mb-2">
              <div class="flex-fill">
                <span v-if="cart.items.total <= 1">
                  {{ cart.items.total }} produto
                </span>
                <span v-else-if="cart.items.total > 1">
                  {{ cart.items.total }} produtos
                </span>
              </div>
              <div class="flex-fill text-right">
                {{ getSubtotal | currency }}
              </div>
            </div>
            <div class="d-flex mb-2 text-muted" v-if="cart.shipping.type == 'receive'">
              <div class="flex-fill">
                Frete
              </div>
              <div class="flex-fill text-right">
                <div v-if="cart.shipping.method === ''">
                  -
                </div>
                <div v-else>
                  {{ cart.shipping.value | currency }}
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div class="flex-fill">
                <h6 class="mb-0">Total</h6>
              </div>
              <div class="flex-fill text-right">
                <h6 class="mb-0">{{ getTotal | currency }}</h6>
              </div>
            </div>
            <hr>
            <button v-on:click="checkoutCart()" class="btn btn-default btn-block font-weight-medium text-uppercase py-2" :disabled="checkout.loading">
              <i class="bx bx-basket font-size-24 align-middle pb-1"></i>
              Finalizar Compra
              <b-spinner v-if="checkout.loading" small class="align-middle" variant="white" role="status"></b-spinner>
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.card-partner .d-flex {
  gap: 10px;
}

.card-partner .logo img {
  border-radius: 50%;
  width: 100%;
  max-width: 100px;
}
</style>
