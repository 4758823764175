<script>
import router from "../../router/routes";
import NavBar from "@/components/nav-bar-store";
import SideBar from "@/components/side-bar-store";
import Footer from "@/components/footer-store";
import Config from '@/components/widgets/store-config';

export default {
  components: { NavBar, SideBar, Footer, Config },
  beforeCreate: function () {
    document.body.className = 'main';
  },
  data() {
    return {
      isMenuCondensed: false,

      cart: {
        items: {
          total: 0,
          list: null
        },
        points: {
          unilevel: 0,
          graduation: 0,
        },
        subtotal: '0.00',
        shipping: {
          method: '',
          value: '0.00',
        },
        total: '0.00'
      }
    };
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
  },
  mounted() {
  },
};
</script>

<template>
  <div id="layout-wrapper">
    <NavBar :isCondensed="isMenuCondensed" />
    <SideBar :isCondensed="isMenuCondensed" />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <Config ref="config" :total="cart.total" />
          <slot />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>