<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import moment from "moment";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";
import ChartGraduation from "@/components/widgets/chart-graduation.vue";
import ChartBonus from "@/components/widgets/chart-bonus";
import ChartPoints from "@/components/widgets/chart-points";
import ChartOrders from "@/components/widgets/chart-orders";
import Carousel from 'primevue/carousel';

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
    ChartGraduation,
    ChartBonus,
    ChartPoints,
    ChartOrders,
    Carousel
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      month_year: moment().format("MM/YY"),

      monthly: {
        status: "inactive",
        date: "-",
      },

      total: {
        prediction: "R$ -,--",
        bonus: "R$ -,--",
        balance: "R$ -,--",
      },

      graduation: {
        id: null,
        name: '-',
        image: null
      },

      statData: null,
      wallets: null,

      partners: {
        options: [
          {
            breakpoint: '1024px',
            numVisible: 5,
            numScroll: 3
          },
          {
            breakpoint: '600px',
            numVisible: 6,
            numScroll: 2
          },
          {
            breakpoint: '480px',
            numVisible: 3,
            numScroll: 1
          }
        ],
        list: null,
      },

      referral: {
        sponsored: process.env.VUE_APP_URL_PAINEL + this.$store.state.account.user.username,
        ecommerce: 'https://' + this.$store.state.account.user.username + '.' + process.env.VUE_APP_URL_ECOMMERCE
      }
    };
  },
  methods: {
    getMonthly() {
      api.get("reports/monthly").then((response) => {
        if (response.data.status == 'success') {
          this.monthly = response.data.monthly;
          this.getStatData();
        }
      });
    },
    getGraduation() {
      api
        .get('reports/graduation')
        .then(response => {
          if (response.data.status == 'success') {
            this.graduation.id = response.data.current.id
            this.graduation.name = response.data.current.name
            this.graduation.image = response.data.current.image
          }
        })
    },
    getTotalPrediction() {
      api.get("reports/bonus/prediction").then((response) => {
        if (response.data.status == "success") {
          this.total.prediction = this.$options.filters.currency(
            response.data.total
          );
          this.getStatData();
        }
      });
    },
    getTotalBonus() {
      api
        .get('reports/bonus/total')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.bonus = this.$options.filters.currency(response.data.total)
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.balance = this.$options.filters.currency(response.data.balance)
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          type: "line",
          icon: "bx-package",
          title: "Plano",
          value: this.account.user.package.name,
          month: false,
          date: "",
        },
        {
          type: "line",
          icon: "bx-time-five",
          title: "Status",
          value: this.monthly.status,
          month: true,
          date: this.monthly.date,
        },
        {
          type: "line",
          icon: "bxs-graduation",
          title: "Graduação",
          value: this.graduation.name,
          month: false,
          date: "",
        },
        {
          type: "line",
          icon: "bx-line-chart",
          title: "Previsão de Bônus",
          value: this.total.prediction,
          month: true,
          date: "",
        },
        {
          type: "line",
          icon: "bx-dollar",
          title: "Total de Bônus",
          value: this.total.bonus,
          month: false,
          date: "",
        },
        {
          type: "line",
          icon: "bx-wallet",
          title: "Saldo Disponível",
          value: this.total.balance,
          month: false,
          date: "",
        }
      ]
    },
    getPartners() {
      api
        .get('store/partners')
        .then((response) => {
          if (response.data.status == 'success') {
            this.partners.list = response.data.list
          }
        })
    }
  },
  mounted() {
    this.getMonthly()
    this.getGraduation()
    this.getTotalPrediction()
    this.getTotalBonus()
    this.getTotalBalance()
    this.getStatData()
    this.getPartners()
  }
};
</script>

<template>
  <Layout>
    <Banners></Banners>

    <div class="card">
      <div class="card-body p-1 d-flex justify-content-between align-items-center">
        <div class="mr-2">
          <div class="bg-light rounded p-2">
            <i class="bx bx-link-alt font-size-24 align-middle"></i>
          </div>
        </div>
        <h5 class="flex-fill m-0 align-items-center font-size-16">
          Link de indicação
        </h5>
        <div class="d-flex align-items-center">
          <div class="ml-1">
            <ShareNetwork network="whatsapp" url="" :title="referral.sponsored">
              <div class="bg-light rounded p-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #0f0d0d;">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263">
                  </path>
                </svg>
              </div>
            </ShareNetwork>
          </div>
          <div class="ml-1">
            <button class="btn btn-link p-0 m-0" v-clipboard:copy="referral.sponsored
              " v-on:click="$toast.success('Copiado!');">
              <div class="bg-light rounded p-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #0f0d0d;">
                  <path
                    d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z">
                  </path>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-4">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" :month="stat.month" :date="stat.date" />
      </div>
    </div>

    <fieldset class="pb-3">
      <legend>Parceiros</legend>

      <Carousel :value="partners.list" :numVisible="7" :numScroll="2" :circular="true" :autoplayInterval="3000" :responsiveOptions="partners.options">
        <template #item="slotProps">
          <router-link :to="'/store/partners/' + slotProps.data.id" class="text-dark">
            <div class="text-center">
              <div>
                <img class="partner-logo" v-if="slotProps.data.image.logo" :src="slotProps.data.image.logo">
                <img class="partner-logo" v-else src=" @/assets/images/logo-app.png" />
              </div>
              <div class="partner-name">
                {{ slotProps.data.name }}
              </div>
            </div>
          </router-link>
        </template>
      </Carousel>
    </fieldset>

    <div class="row">
      <div class="col-md-12 col-lg-6">
        <fieldset class="card-h-fix">
          <legend>Graduação <sup class="font-weight-light">{{ month_year }}</sup></legend>
          <div class="d-flex justify-content-center card-h-fix">
            <ChartGraduation />
          </div>
        </fieldset>
      </div>
      <div class="col-md-12 col-lg-6">
        <fieldset class="card-h-fix">
          <legend>Bonificações <sup class="font-weight-light">{{ month_year }}</sup></legend>
          <div class="d-flex justify-content-center card-h-fix">
            <ChartBonus />
          </div>
        </fieldset>
      </div>
      <div class="col-md-12 col-lg-6">
        <fieldset class="card-h-fix">
          <legend>Pontuações <sup class="font-weight-light">{{ month_year }}</sup></legend>
          <div class="d-flex justify-content-center card-h-fix">
            <ChartPoints />
          </div>
        </fieldset>
      </div>
      <div class="col-md-12 col-lg-6">
        <fieldset class="card-h-fix">
          <legend>Pedidos <sup class="font-weight-light">{{ month_year }}</sup></legend>
          <div class="d-flex justify-content-center card-h-fix">
            <ChartOrders />
          </div>
        </fieldset>
      </div>
    </div>
  </Layout>
</template>

<style>
.partner-logo {
  border-radius: 50%;
  width: 100px;
}

.p-carousel {
  position: relative;
  margin: 0 -20px;
}

.p-carousel .p-carousel-content .p-carousel-prev {
  position: absolute;
  top: -50px;
  right: 42px;
  background: #fff !important;
  box-shadow: none !important;
}

.p-carousel .p-carousel-content .p-carousel-next {
  position: absolute;
  top: -50px;
  right: 10px;
  background: #fff !important;
  box-shadow: none !important;
}

.p-carousel .p-carousel-indicators {
  display: none;
}

.p-carousel .partner-name {
  margin-top: 10px;
  padding: 0 20px;
  line-height: 13px;
  font-size: 13px;
}
</style>
