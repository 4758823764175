<script>
import * as d3 from 'd3';
import { OrgChart } from 'd3-org-chart';

export default {
  props: {
    data: {
      default: null,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    data(value) {
      this.renderChart(value);
    },
  },
  computed: {
    svgHeight: function () {
      var height = window.innerHeight;
      return height - 350;
    }
  },
  methods: {
    renderChart(data) {
      this.chart = new OrgChart();
      this.chart
        .compact(false)
        .nodeWidth(() => 200)
        .nodeHeight(() => 50)
        .childrenMargin(() => 150)
        .setActiveNodeCentered(true)
        .container('#matriz')
        .svgHeight(this.svgHeight)
        .buttonContent(({
          node
        }) => {
          return `<div style="
              border-radius: 3px;
              padding: 5px 10px;
              font-size: 10px;
              color: #fff;
              margin:auto auto;
              background-color: #333;
            "> <span style="font-size:9px">${node.children
              ? `<i class="fas fa-chevron-up"></i>`
              : `<i class="fas fa-chevron-down"></i>`
            }</span> ${node.data._directSubordinates}</div>`;
        })
        .linkUpdate(function (d) {
          d3.select(this)
            .attr('stroke', (d) =>
              d.data._upToTheRootHighlighted ? '#333' : '#333'
            )
            .attr('stroke-width', () =>
              d.data._upToTheRootHighlighted ? 15 : 3
            );
        })
        .nodeContent(
          (d) =>
            `<div style='
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #fff;
              border: 3px solid #333;
              border-radius: 1000px;
              width: 200px;
              height: 100%;
              font-size: 13px;
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            '>
              <span>${d.data.username}</span>
            </div>`
        )
        .data(data)
        .render();
    },
  },
  mounted() {
  },
};
</script>

<template>
  <div id="matriz" class="bg-white">
    <div class="text-center pt-3">
      <button class="btn btn-default btn-sm ml-1" v-on:click="chart.zoomOut()"><i class="bx bx-minus-circle align-middle font-size-20"></i></button>
      <button class="btn btn-default btn-sm ml-1" v-on:click="chart.zoomIn()"><i class="bx bx-plus-circle align-middle font-size-20"></i></button>
      <button class="btn btn-default btn-sm ml-1" v-on:click="chart.fit().render()"><i class="bx bx-align-middle align-middle font-size-20"></i></button>
      <button class="btn btn-default btn-sm ml-1" v-on:click="chart.expandAll().fit()"><i class="bx bx-expand align-middle font-size-20"></i></button>
      <button class="btn btn-default btn-sm ml-1" v-on:click="chart.collapseAll().fit()"><i class="bx bx-collapse align-middle font-size-20"></i></button>
      <button class="btn btn-default btn-sm ml-1" v-on:click="chart.fullscreen()"><i class="bx bx-fullscreen align-middle font-size-20"></i></button>
    </div>
  </div>
</template>